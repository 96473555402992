
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

const APIURL = "http://dev-spotlight.novatrans.cloud/api.php"
const myHeaders = new Headers();

myHeaders.append("X-Authorization", "c29sYnl0ZTEyMzQ=");

const regex = /(?=>([^<]+))/g;
const localStorageItems = {}

function updateStorage(query: string, list: string) {
  window.localStorage.setItem(query, list)
}

export const novatransWS = {
  async get(query:string) {

    const formdata = new FormData();
    formdata.append("u", `${NOVATRANS_USER}`);
    formdata.append("p", `${NOVATRANS_PASSWORD}`);
    formdata.append("ur", `${NOVATRANS_SERVER_URL}`);
    formdata.append("b", `${NOVATRANS_BD}`);
    formdata.append("s", `${NOVATRANS_SERVER_NAME}`);
    formdata.append("query", `${query}`);


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    const results: NovatransData = await fetch(APIURL, requestOptions)
    .then(r => r.text())
    .then(responseText => {
      let m
      let results = ""

      while ((m = regex.exec(responseText)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        m.forEach((match) => {
            results = match
        });
      }

      //results = JSON.parse(results)

      if (localStorageItems != results) {
        // console.log("updating obj")
        updateStorage(query, results)
        return JSON.parse(results)
      }
      return results
    })
    .catch(err => {
      return err
    })

    if (window.localStorage.getItem(query)) {
      const localItem : string = window.localStorage.getItem(query) as string;
      try {
        const localStorageItems = JSON.parse(localItem)
        // console.log("return obj from localstorage")
        return localStorageItems

      } catch (error) {
        console.log(error)
      }
    }
    return results
  }
}

// const APIURL = NOVATRANS_SERVER_URL
// const APIURL = 'http://192.168.1.72:81/NovaTrans.ServiciosWeb/novatransws.asmx'
// myHeaders.append("Content-Type", "multipart/form-data;");
// myHeaders.append("Content-Type", "text/xml;charset=utf-8");
// const usuario = 'admin'
// const password = 'admin'
// const baseDatos = 'NTDesarrollo'
// const servidor = 'server\\desarrollo'

// const raw = `<?xml version="1.0" encoding="utf-8"?>
    //           <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope"> 
    //             <soap12:Header></soap12:Header> 
    //               <soap12:Body>   
    //                 <GetDataToSpotlight  xmlns="http://tempuri.org/">     
    //                   <usuario>${NOVATRANS_USER}</usuario>     
    //                   <password>${NOVATRANS_PASSWORD}</password>     
    //                   <baseDatos>${NOVATRANS_BD}</baseDatos>     
    //                   <servidor>${NOVATRANS_SERVER_NAME}</servidor>     
    //                   <input>${query}</input>   
    //                 </GetDataToSpotlight> 
    //               </soap12:Body>
    //             </soap12:Envelope>`;

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    // };