import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/main.scss'

const app = createApp(App).use(router)

app.config.errorHandler = (err: any, instance: any, info: any) => {
    console.log(instance, info)
    window.alert(`Error: ${err}`);
}

app.mount('#app')
