import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HelpView from "@/views/HelpView.vue"
import IndexView from "@/views/IndexView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: IndexView
  },
  
  {
    path: "/help",
    name: "help",
    component: HelpView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
