import { ref, computed } from 'vue';
import { novatransWS } from "@/services/novatransWS";

import router from "@/router";
 
const query = ref("");
const isValidQuery = ref(true);

const list = ref({});
const loading = ref(false);

// Module items for pills navigation
const modules = computed(() => {
    const moduleList = [];
    for (const [key, value] of Object.entries(list.value)) {
        if (value != "") {
            moduleList.push(key);
        }
    }
    return moduleList;
});

//Search function
async function handleSearch(query: string) {
  
  if (query.length < 3) {

    isValidQuery.value = false

  } else {

    if (router.currentRoute.value.name != 'index') {
      router.push('/');
    }

    isValidQuery.value = true
    loading.value = true
    
    list.value = await novatransWS.get(query);
    loading.value = false
  }
}

export { query, isValidQuery, list, modules, loading, handleSearch }